import './navigation.css';
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from '../../constants';
import {Products} from "../../Api/";


function NavigationBar() {
  return (
    <>
      <div className='topBar'>
      <div class="marquee-wrapper">
        <div class="marquee">
          <div class="marquee-content">
                  <div class="marquee-item">
                    
                  </div>
              {Object.values(Products).map((ctg, index) => (
                ctg.map((prd, subIndex) => (
                  <div class="marquee-item" key={`${index}-${subIndex}`}>
                    <img src={prd.image} alt={prd.name} />
                    <p>{prd.name}</p>
                  </div>
                ))
              ))}
          </div>
          <div class="marquee-content">
              {Object.values(Products).map((ctg, index) => (
                ctg.map((prd, subIndex) => (
                  <div class="marquee-item" key={`${index}-${subIndex}`}>
                    <img src={prd.image} alt={prd.name} />
                    <p>{prd.name}</p>
                  </div>
                ))
              ))}
          </div>
        </div>
      </div>

      {/* <marquee>
            <div className='d-flex align-items-center justify-content-center'>
              
            </div>
          </marquee> */}
      </div>
      <Navbar expand="lg" className="navBar">
        <Container >
          <Nav className="linksContainer align-items-center navItems">
              <Nav.Link href="/" className='link'>Home</Nav.Link>
              <Nav.Link href="/about" className='link'>About</Nav.Link>
              <Nav.Link href="/products" className='link'>Products</Nav.Link>
            </Nav>
          <Navbar.Brand href="/" className="logo"> <img src={images.logo} /><span className='d-block'>Pakistan Oil Mills</span></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Nav className="linksContainer justify-content-end align-items-center navItems">
              <Nav.Link href="/career" className='link'>Career</Nav.Link>
              <Nav.Link href="/newsandevents" className='link'>News & Events</Nav.Link>
              <Nav.Link href="/contact" className='link'>Contact</Nav.Link>
              {/* <Nav.Link href="/login" className='link btn btn-nav'>Login</Nav.Link> */}
            </Nav>
          <Navbar.Collapse id="basic-navbar-nav" className='navItemsResponsive'>
            <Nav className="ms-auto">
              <Nav.Link href="/" className='link'>Home</Nav.Link>
              <Nav.Link href="/about" className='link'>About</Nav.Link>
              <Nav.Link href="/products" className='link'>Products</Nav.Link>

              <Nav.Link href="/newsandevents" className='link'>News & Events</Nav.Link>
              <Nav.Link href="/contact" className='link'>Contact</Nav.Link>
              <Nav.Link href="/login" className='link btn btn-nav'>Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <img src={images.wave} className='wave' />
      </Navbar>
    </>
  );
}

export default NavigationBar;
