import { icons, images } from "../../constants";
import "./about.style.css";
import { Container, Row, Col } from "react-bootstrap";
import { Testimonial } from "../../Components";

const About = () => {
  return (
    <>
      <div className="about">
        <div className="pageTitle mb-5">
          <h4>Who We Are</h4>
        </div>
        <Container>
          <Row>
            <Col lg={6} md={12} sm={12} xs={12}>
              <div className="imageContainer">
                <img src={images.ad9} />
                <img src={images.banner1} />
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} xs={12}>
              <div className="aboutContent">
                <p className="heading">About</p>
                <h1>Pakistan Oil Mills</h1>
                <p>
                  Pakistan Oil Mills (Pvt) Ltd. established in the year 1990,
                  Its composite Vegetable Oil and Banaspati manufacturing Unit.
                  It is one of the largest edible oil company in Pakistan to
                  install a state of art plant called Continuous Process Unit
                  (CPU)
                </p>
                <span>Kotri, Hyderabad - Pakistan</span>
                <h3>+92 22 3871100-1700</h3>
              </div>
            </Col>
          </Row>
          <div className="statics">
            <Row>
              <Col lg={2}>
                <h3>31+</h3>
                <p>Years of Experience</p>
              </Col>
              <Col lg={2}>
                <h3>10+</h3>
                <p>Products</p>
              </Col>
              <Col lg={2}>
                <h3>1000+</h3>
                <p>Staffs</p>
              </Col>
              <Col lg={2}>
                <h3>100000+</h3>
                <p>Happy Customers</p>
              </Col>
              <Col lg={4}>
                <p className="desc">
                  Pakistan Oil Mills Products Are Prepared Strictly In
                  Accordance With The Pakistan Standard Quality Control
                  Authority.
                </p>
              </Col>
            </Row>
          </div>
        </Container>

        <div className="customers">
          <Container>
            <h1>Happy Customers</h1>
            <Testimonial
              slides={1}
              breakpoints={{
                1220: { slidesPerView: 3 },
                770: { slidesPerView: 2 },
              }}
              space={20}
              wrap={1}
            />
          </Container>
        </div>

        <div className="certification">
          <Container fluid={"xl"}>
            <div className="contentWrepper">
              <h1>
                About The <span>Pakistan Oil Mills!</span>
              </h1>
              <Row>
                <Col lg={8}>
                  <div className="context">
                    <p>
                      <h6>Our Vision</h6>
                      <img src={icons.vision} className="icon" /> To be a leading Company, committed to total Customer
                      stisfaction by providing high quality value added products
                      catering to customers’ needs through contionuous research
                      & development.
                      <br />
                      <br />
                      <h6>Our Mission</h6>
                      <img src={icons.mission}  className="icon" /> The Company has taken on a mission to introduce new
                      products with improved quality in its line of business,
                      continuously improve Company’s profitability and meet its
                      financial commitments. It further commits to focus on the
                      professional growth of its employees and give a fair
                      return to its shareholders while complying with the best
                      practices of Corporate Governance.
                      <br />
                      <br />
                      <h6>More About Us</h6>
                      <Row>
                        <Col lg={4} md={4} sm={6} xs={6}>
                          Pakistan Oil Mills (Pvt) Ltd. established in the year
                          1990, Its composite Vegetable Oil and Banaspati
                          manufacuring Unit.
                          <br />
                          <br />
                          Pakistan Oil Mills (POM) is a one of the largest edible
                          oil company in Pakistan to install a state of art plant
                          called Continuous Process Unit (CPU)
                        </Col>
                        <Col lg={8} md={8} sm={6} xs={6}>
                          <div className="img" style={{backgroundImage: `url(${images.company3})`}}></div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="mt-3">
                          The introduction of continuous processing plant has been
                          integral part of the company's growth. In keeping with the
                          motto of the company, this automation has guaranteed
                          constant production and uniform quality to ensure that
                          excellence is delivered to our customers year after year.
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="mt-3">
                          Thanks to the technologies adoption, we now proudly offer
                          our customers further improved quality. Our products serve
                          a longer shelf life, for the convenience of our customers
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col lg={6} md={6} sm={6} xs={6}>
                          <div className="imgContainer" style={{height: "calc(100% + 40px)"}}>
                            <div className="img" style={{backgroundImage: `url(${images.company1})`}}></div>
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}>
                          All our brands have been developed on the basis of
                          consumer feed back and preferences derived through in
                          depth market research. Over the year we have invested
                          significally in nurturing these brands in terms of
                          quality, health, packaging and market penetration.
                        </Col>
                        
                        <Col lg={6} md={6} sm={6} xs={6} className="mt-5">
                          POM is committed to produce high quality edible Oil, Ghee,
                          Margarine and HORECA to achieve complete customer
                          satisfaction and at most competitive price, Pakistan Oil
                          Mills has loyal consumer base its brand "Pure, Naz, Sun
                          and Pak Cooking Oil & Banaspati".
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="mt-5">
                          <div className="imgContainer" style={{height: "calc(100% + 40px)", position: "relative",top: "-40px",}}>
                            <div className="img" style={{backgroundImage: `url(${images.company2})`}}></div>
                          </div>
                        </Col>
                        
                      </Row>
                    </p>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <video width="100%" controls>
                        <source src={images.video2} type="video/mp4" />
                      </video>
                    </Col>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="line">
                    <div>
                      <img src={images.certificate1} />
                    </div>
                    <div>
                      <img src={images.certificate2} />
                    </div>
                  </div>
                  <div className="line centered">
                    <div>
                      <img src={images.certificate3} />
                    </div>
                    <div>
                      <img src={images.certificate4} />
                    </div>
                  </div>
                  <div className="line">
                    <div>
                      <img src={images.certificate5} />
                    </div>
                    <div>
                      <img src={images.certificate6} />
                    </div>
                  </div>
                  <div className="line">
                    <div>
                      <img src={images.certificate7} />
                    </div>
                    <div>
                      <img src={images.certificate8} />
                    </div>
                  </div>
                  <div className="line">
                    <div className="col-12">
                      <img src={images.certificate9} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default About;
