import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout, Home, About, Products, ProductDetail, Contact, NewsAndEvents, Career } from './Pages';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="products" element={<Products />} />
          <Route path="products/:category/:name" element={<ProductDetail />} />
          <Route path="career" element={<Career />} />
          <Route path="newsandevents" element={<NewsAndEvents />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
