import sample1 from "../assets/images/cehfSample.jpg";
import sample2 from "../assets/images/chefSample2.jpg";
import sample3 from "../assets/images/chefSample3.jpg";
import banner1 from "../assets/images/company.jpg";
import banner2 from "../assets/images/bannerImage2.jpg";
import banner3 from "../assets/images/bannerImage3.jpg";
import logo from "../assets/images/logo.png";
import logo2 from "../assets/images/logo2.png";
import wave from "../assets/images/wave.png";
import frame1 from "../assets/images/frame1.png";
import frame2 from "../assets/images/frame2.png";
import frame3 from "../assets/images/frame3.png";
import company from "../assets/images/company.jpg";
import NazCookingOil from "../assets/images/brand/products/NazCookingOil.png";
import PureCookingOil from "../assets/images/brand/products/PureCookingOil.png";
import FryOlaCookingOil from "../assets/images/brand/products/FryOlaCookingOil.png";
import SunCookingOil from "../assets/images/brand/products/SunCookingOil.png";
import PakCookingOil from "../assets/images/brand/products/PakCookingOil.png";
import SunBanaspati from "../assets/images/brand/products/SunBanaspati.png";
import PakBanaspati from "../assets/images/brand/products/PakBanaspati.png";
import NazBanaspati from "../assets/images/brand/products/NazBanaspati.png";
import PureBanaspati from "../assets/images/brand/products/PureBanaspati.png";
import KawalityMargarine from "../assets/images/brand/products/KawalityMargarine.png";
import RoyalMavaMargarine from "../assets/images/brand/products/RoyalMargarine.png";
import pureCanola from "../assets/images/brand/products/PureCanolaOil.png";

import NazCookingOilThumbnail from "../assets/images/brand/thumbnails/NazCookingOil.png";
import PureCookingOilThumbnail from "../assets/images/brand/thumbnails/PureCookingOil.png";
import FryOlaCookingOilThumbnail from "../assets/images/brand/thumbnails/FryOlaCookingOil.png";
import SunCookingOilThumbnail from "../assets/images/brand/thumbnails/SunCookingOil.png";
import PakCookingOilThumbnail from "../assets/images/brand/thumbnails/PakCookingOil.png";
import SunBanaspatiThumbnail from "../assets/images/brand/thumbnails/SunBanaspati.png";
import PakBanaspatiThumbnail from "../assets/images/brand/thumbnails/PakBanaspati.png";
import NazBanaspatiThumbnail from "../assets/images/brand/thumbnails/NazBanaspati.png";
import PureBanaspatiThumbnail from "../assets/images/brand/thumbnails/PureBanaspati.png";
import KawalityMargarineThumbnail from "../assets/images/brand/thumbnails/KawalityMargarine.png";
import RoyalMavaMargarineThumbnail from "../assets/images/brand/thumbnails/RoyalMargarine.png";
import pureCanolaThumbnail from "../assets/images/brand/thumbnails/PureCanolaOil.png";

import NazCookingOilThumbnail2 from "../assets/images/brand/new/naz1.jpg";
import PureCookingOilThumbnail2 from "../assets/images/brand/new/pure1.jpg";
import FryOlaCookingOilThumbnail2 from "../assets/images/brand/new/fryola1.jpg";
import SunCookingOilThumbnail2 from "../assets/images/brand/new/sun1.jpg";
import PakCookingOilThumbnail2 from "../assets/images/brand/new/pak1.jpg";
import KawalityMargarineThumbnail2 from "../assets/images/brand/new/kawality1.jpg";
import RoyalMavaMargarineThumbnail2 from "../assets/images/brand/new/royal1.jpg";

import ad1 from "../assets/images/advertisement/pure1.jpeg";
import ad2 from "../assets/images/advertisement/naz1.jpeg";
import ad3 from "../assets/images/advertisement/pure2.jpeg";
import ad4 from "../assets/images/advertisement/naz2.jpeg";
import ad5 from "../assets/images/advertisement/ad1.jpeg";
import ad6 from "../assets/images/advertisement/ad2.jpeg";
import ad7 from "../assets/images/advertisement/ad3.jpeg";
import ad8 from "../assets/images/advertisement/ad4.jpeg";
import ad9 from "../assets/images/advertisement/ad5.jpeg";
import avatar1 from "../assets/images/avatar1.jpeg";
import avatar2 from "../assets/images/avatar2.jpeg";
import avatar3 from "../assets/images/avatar3.jpeg";
import customer1 from "../assets/images/customers/customer1.png";
import customer2 from "../assets/images/customers/customer2.png";
import customer3 from "../assets/images/customers/customer3.png";
import customer4 from "../assets/images/customers/customer4.png";
import certificate1 from "../assets/images/certificate/cert1.png";
import certificate2 from "../assets/images/certificate/cert2.png";
import certificate3 from "../assets/images/certificate/cert3.png";
import certificate4 from "../assets/images/certificate/cert4.png";
import certificate5 from "../assets/images/certificate/cert5.png";
import certificate6 from "../assets/images/certificate/cert6.png";
import certificate7 from "../assets/images/certificate/cert7.png";
import certificate8 from "../assets/images/certificate/cert8.png";
import certificate9 from "../assets/images/certificate/cert9.png";
import adNew1 from "../assets/images/advertisement/new1.jpeg";
import adNew2 from "../assets/images/advertisement/new2.jpeg";
import calender from "../assets/images/pdf/calenderPom.pdf";
import bakeryCalender from "../assets/images/pdf/bakeryCalender.pdf";
import pomCalenderThumbnail1 from "../assets/images/pdf/pomCalenderThumbnail1.jpeg";
import pomCalenderThumbnail2 from "../assets/images/pdf/pomCalenderThumbnail2.jpeg";
import pomCalenderThumbnail3 from "../assets/images/pdf/pomCalenderThumbnail3.jpeg";
import pomCalenderThumbnail4 from "../assets/images/pdf/pomCalenderThumbnail4.jpeg";
import bakeryCalenderThumbnail1 from "../assets/images/pdf/bakeryCalenderThumbnail1.jpeg";
import bakeryCalenderThumbnail2 from "../assets/images/pdf/bakeryCalenderThumbnail2.jpeg";
import bakeryCalenderThumbnail3 from "../assets/images/pdf/bakeryCalenderThumbnail3.jpeg";
import bakeryCalenderThumbnail4 from "../assets/images/pdf/bakeryCalenderThumbnail4.jpeg";
import video1 from "../assets/images/video/video1.mp4";
import video2 from "../assets/images/video/video2.mp4";
import video3 from "../assets/images/video/video3.mp4";
import video4 from "../assets/images/video/video4.mp4";
import company1 from "../assets/images/company1.jpeg";
import company2 from "../assets/images/company2.jpeg";
import company3 from "../assets/images/company3.jpeg";
import company4 from "../assets/images/company4.jpeg";
// import sun from "../assets/images/sun.jpg";


export default {
  sample1,
  sample2,
  sample3,
  banner1,
  banner2,
  banner3,
  logo,
  NazCookingOil,
  PureCookingOil,
  FryOlaCookingOil,
  SunCookingOil,
  PakCookingOil,
  SunBanaspati,
  PakBanaspati,
  NazBanaspati,
  PureBanaspati,
  KawalityMargarine,
  RoyalMavaMargarine,
  ad1,
  ad2,
  ad3,
  ad4,
  ad5,
  ad6,
  ad7,
  ad8,
  ad9,
  avatar1,
  avatar2,
  avatar3,
  customer1,
  customer2,
  customer3,
  customer4,
  certificate1,
  certificate2,
  certificate3,
  certificate4,
  certificate5,
  certificate6,
  certificate7,
  certificate8,
  certificate9,
  logo2,
  wave,
  frame1,
  frame2,
  frame3,
  company,
  pureCanola,
  adNew1,
  adNew2,
  calender,
  bakeryCalender,
  pomCalenderThumbnail1,
  pomCalenderThumbnail2,
  pomCalenderThumbnail3,
  pomCalenderThumbnail4,
  bakeryCalenderThumbnail1,
  bakeryCalenderThumbnail2,
  bakeryCalenderThumbnail3,
  bakeryCalenderThumbnail4,
  NazCookingOilThumbnail,
PureCookingOilThumbnail,
FryOlaCookingOilThumbnail,
SunCookingOilThumbnail,
PakCookingOilThumbnail,
SunBanaspatiThumbnail,
PakBanaspatiThumbnail,
NazBanaspatiThumbnail,
PureBanaspatiThumbnail,
KawalityMargarineThumbnail,
RoyalMavaMargarineThumbnail,
pureCanolaThumbnail,
NazCookingOilThumbnail2,
PureCookingOilThumbnail2,
FryOlaCookingOilThumbnail2,
SunCookingOilThumbnail2,
PakCookingOilThumbnail2,
KawalityMargarineThumbnail2,
RoyalMavaMargarineThumbnail2,
video1,
video2,
video3,
video4,
company1,
company2,
company3,
company4,
};
