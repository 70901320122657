import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import ImageParallax from "simple-parallax-js";

// import SendIcon from '@mui/icons-material/Send';

import Button from "@mui/material/Button";
import "./career.style.css";
import { Heading } from "../../Components";
import { images } from "../../constants";

const Career = () => {
  return (
    <div className="career">
      <div className="pageTitle">
        <h4>Career</h4>
      </div>

      <div className="content">
        <Heading title={"Build Career With Pom"} class={"my-5"} />

        <Container>
          <div className="formContent">
            <div className="bg">
              <ImageParallax orientation="down left">
                <img
                  src={images.company3}
                  alt="Banner Image"
                  className="bgImage"
                />
              </ImageParallax>
              <div className="bgOverlay"></div>
            </div>
            <Col lg={6} md={8} sm={12}>
              <form method="POST">
                <Row>
                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        id="standard-required"
                        label="First Name"
                        fullWidth
                        variant="filled"
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        fullWidth
                        id="standard-required"
                        label="Last Name"
                        variant="filled"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        fullWidth
                        id="standard-required"
                        label="Email"
                        type="email"
                        variant="filled"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        fullWidth
                        id="standard-required"
                        label="Phone"
                        type="number"
                        variant="filled"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        fullWidth
                        id="standard-required"
                        label="City"
                        variant="filled"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        fullWidth
                        id="standard-required"
                        label="CNIC"
                        type={"number"}
                        variant="filled"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        fullWidth
                        id="standard-required"
                        label="Recent Degree Acquired"
                        
                        variant="filled"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        fullWidth
                        id="standard-required"
                        label="Are of Study"
                        variant="filled"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        fullWidth
                        id="standard-required"
                        label="Years Of Experience"
                        variant="filled"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        required
                        fullWidth
                        id="standard-required"
                        label="Applying For the Post"
                        variant="filled"
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="input d-flex align-items-center ">
                      <TextField
                        id="standard-multiline-static"
                        label="Message"
                        fullWidth
                        multiline
                        rows={4}
                        // defaultValue="Default Value"
                        variant="filled"
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="input d-flex align-items-center ">
                      <Button variant="contained" fullWidth>
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Career;
